.App {
  text-align: center;
}



.App-header {
  background-color: #5a86dd;
  display: flex;
  position: static;
  z-index: 1;
}

.App-link {
  color: #61dafb;
}


.App-body {
  background-color: #d46161;
  display: flex;
  width: 100%;
  height: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.App-background {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.App-ui {
  position: absolute;
  display: flex;
  align-content: center;
  top: 46.5%;
  z-index: 2;
}

img {
  width: 80px;
  height: 80px;
}
